// React
import React from "react";
// Framework
import { forwardType, External, Externals } from "@mefisto/web";
// Components
import FilmStrip from "./images/film-strip.png";
import FilmStripYellowTitleBackground from "./images/film-strip-yellow-title-background.png";
import FilmStripRedTitleBackground from "./images/film-strip-red-title-background.png";
import FilmStripBlueTitleBackground from "./images/film-strip-blue-title-background.png";
import PosterMarcel from "./images/poster-marcel.jpg";
import PosterMariana from "./images/poster-mariana.jpg";
import PosterOndrej from "./images/poster-ondrej.jpg";
import PosterHeroVillain from "./images/poster-hero-villain.png";
import PosterMovieTitle from "./images/poster-movie-title.png";
import PosterPhotoStrip from "./images/poster-photo-strip.png";
import BackgroundYellow from "./images/background-yellow.jpg";
import SwifthoodCover from "./images/swifthood-cover.jpg";

export default forwardType(Externals, () => {
  // prettier-ignore
  return (
    <Externals>
      {/* Images */}
      <External name="FILM_STRIP" path={FilmStrip} />
      <External name="FILM_STRIP_YELLOW_TITLE_BACKGROUND" path={FilmStripYellowTitleBackground} />
      <External name="FILM_STRIP_RED_TITLE_BACKGROUND" path={FilmStripRedTitleBackground} />
      <External name="FILM_STRIP_BLUE_TITLE_BACKGROUND" path={FilmStripBlueTitleBackground} />
      <External name="POSTER_MARCEL" path={PosterMarcel} />
      <External name="POSTER_MARIANA" path={PosterMariana} />
      <External name="POSTER_ONDREJ" path={PosterOndrej} />
      <External name="POSTER_HERO_VILLAIN" path={PosterHeroVillain} />
      <External name="POSTER_MOVIE_TITLE" path={PosterMovieTitle} />
      <External name="POSTER_PHOTO_STRIP" path={PosterPhotoStrip} />
      <External name="BACKGROUND_YELLOW" path={BackgroundYellow} />
      <External name="SWIFTHOOD_COVER" path={SwifthoodCover} />
    </Externals>
  );
});
