// React
import React from "react";
// Framework
import { forwardType, Themes } from "@mefisto/web";
// Components
import { DarkTheme } from "./components/DarkTheme";

export default forwardType(Themes, () => (
  <Themes defaultTheme="dark">
    <DarkTheme />
  </Themes>
));
