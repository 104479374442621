// React
import React from "react";
// Framework
import { forwardType, App } from "@mefisto/web";
// Loader
import { Loader } from "loader";
import { portal } from "@mefisto/plugin-portal/permissions";

export default forwardType(App, () => (
  <App
    enabled
    name="PRESENTATION"
    type="NATIVE"
    title="Presentation"
    priority={0}
    loader={<Loader />}
    navigation={{
      basename: "/",
      routes: {
        default: "/",
        notAuthenticated: "/",
      },
      paths: {
        landing: "/",
        events: "/predstaveni",
        terms: "/terms-of-service",
        privacy: "/privacy-policy",
      },
    }}
    resource={{
      source: "ui",
    }}
    permissions={[...portal()]}
  />
));
