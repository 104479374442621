// React
import React from "react";
// Framework
import {
  Portal,
  Firebase,
  Navigation,
  Localization,
  Language,
  Format,
  FormatPrice,
  Interceptors,
  LocalizationInterceptor,
} from "@mefisto/web";
// Apps
import PresentationApp from "@apps/presentation";
// Components
import { Externals } from "externals";
import { Helmet } from "helmet";
import { Themes } from "themes";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

export default () => (
  <Portal>
    <Externals />
    <Helmet />
    <Themes />
    <Firebase modules={["auth", "database"]} />
    <Localization
      fallback="en"
      languages={[
        <Language locale="en" title="English" />,
      ]}
    />
    <Format>
      <FormatPrice locale="en" maximumFractionDigits={0} />
    </Format>
    <Interceptors
      interceptors={[
        <LocalizationInterceptor />,
      ]}
    />
    <Navigation
      routes={{
        home: "/",
      }}
    />
    <PresentationApp />
  </Portal>
);
